@use '@angular/material' as mat;

@include mat.core();

$primary: mat.define-palette(mat.$teal-palette, 200);
$accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);

$ohana-theme: mat.define-dark-theme(
    (
      color: (
        primary: $primary,
        accent: $accent,
      ),
      density: -1,
    )
);

@include mat.all-component-themes($ohana-theme);

@include mat.button-density(0);
@include mat.input-density(-1);

.mdc-dialog__container {
  white-space: pre-wrap;
}

.mdc-button__label {
  white-space: nowrap;
}

mat-form-field.no-sub {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}
