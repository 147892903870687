html,
body {
  height: 100%;
}

body {
  margin: 0;
  color: #fff;
  font-family: Roboto, 'Helvetica Neue', sans-serif;

  background-color: #303030;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-strong {
  opacity: 0.5;
  cursor: pointer;

  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.mat-typography {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
  }
}

$cardBoxPadding: 1rem;

.card-box {
  display: flex;
  flex-direction: column;

  padding: $cardBoxPadding;

  border: 1px solid #595959;
  border-radius: 6px;

  background-color: #303030;

  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  //box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  position: relative;

  .card-box-footer {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    margin: $cardBoxPadding #{-1 * $cardBoxPadding} #{-1 * $cardBoxPadding};
    border-top: 0;
  }
}

.bordered-box {
  border: 1px solid #fff;
  border-radius: 7px;
}

table {
  td, th {
    vertical-align: middle;
  }
}

.table-row-dark {
  background: #212529 !important;

  &:hover {
    background: #41434b !important;
  }
}

.table-row-light {
  background: #2c3034 !important;

  &:hover {
    background: #41434b !important;
  }
}

.mat-mdc-table .mdc-data-table__row {
 height: 35px !important;
}

.mdc-data-table__cell {
  padding: 0 8px !important;
  text-align: center !important;
}

.mdc-data-table__header-cell {
  padding: 0 !important;
  text-align: center !important;

  .mat-sort-header-content {
    padding-left: 12px;
  }
}
