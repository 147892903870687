.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-grow-1 {
  min-width: 0;
}

.content-center {
  display: flex;

  flex-wrap: wrap;

  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.vertical-align-middle {
  vertical-align: middle;
}

.w-0 {
  width: 0;
}

.h-1 {
  height: 0;
}
